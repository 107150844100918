import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import './topbar.scss';
import { Settings } from "@material-ui/icons";
import firebase from '../../firebaseConfige'; // Import your Firebase configuration
import logoImage from '../../image/logo-removebg-preview.png'; // Import your logo image

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        // Check if there's a user signed in
        firebase.auth().onAuthStateChanged(async (user) => {
          if (user) {
            // Fetch user profile data from Firestore
            const userProfileRef = firebase.firestore().collection('admin-user').doc(user.uid);
            const userProfileSnapshot = await userProfileRef.get();
  
            if (userProfileSnapshot.exists) {
              setUserProfile(userProfileSnapshot.data());
            } else {
              console.log('No user profile found.');
            }
          } else {
            console.log('No user signed in.');
          }
        });
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };
    fetchUserProfile();
  }, []);
  
  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className={isScrolled ? "navbar scrolled" : "navbar"}>
      <div className="container">
        <div className="topbarWrapper">
          <div className="topLeft">
            {/* Make the logo clickable to navigate to Dashboard */}
            <Link to="/Dashboard" className="logo">
              <img src={logoImage} alt="Logo" />
            </Link>
          </div>
        
          <div className="right">
            {userProfile && (
              <div className="user-profile">
                {/* Make the profile picture clickable if you have a specific route */}
                {userProfile.profilePic && (
                  <Link to="/profile">
                    <img src={userProfile.profilePic} alt="Profile" className="profile-picture" />
                  </Link>
                )}
                {/* Make the username clickable if you have a specific route */}
                <Link to="/UserA" className="username">{userProfile.name}</Link>
              </div>
            )}
          </div>
          <div className="topbarIconContainer">
            {/* Make the Settings icon clickable */}
            <Link to="/settings">
              <Settings style={{ color: "white" }} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
