import React, { useState, useEffect } from 'react';
import './settings.scss';
import Navbar from '../../component/topbar/Topbar';

const Settings = () => {
  // Load settings from local storage or use default values
  const darkModePref = localStorage.getItem('darkMode') === 'true';
  const [darkMode, setDarkMode] = useState(darkModePref);
  const [themeColor, setThemeColor] = useState(localStorage.getItem('themeColor') || '#007bff');
  const [fontSize, setFontSize] = useState(localStorage.getItem('fontSize') || 'medium');
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'en');
  const [notifications, setNotifications] = useState(localStorage.getItem('notifications') || 'email');
  const [dateTimeFormat, setDateTimeFormat] = useState(localStorage.getItem('dateTimeFormat') || '24h');

  // Toggle dark mode
  const toggleDarkMode = () => {
    const newDarkMode = !darkMode;
    setDarkMode(newDarkMode);
    localStorage.setItem('darkMode', newDarkMode.toString());
  };

  // Apply settings when state changes
  useEffect(() => {
    const body = document.querySelector('body');
    body.style.backgroundColor = darkMode ? '#333' : '#fff';
    body.style.color = darkMode ? '#fff' : '#333';
    body.style.fontSize = fontSize === 'small' ? '14px' : fontSize === 'medium' ? '16px' : '18px';
    body.style.setProperty('--theme-color', themeColor);
    localStorage.setItem('themeColor', themeColor);
    localStorage.setItem('fontSize', fontSize);
    localStorage.setItem('language', language);
    localStorage.setItem('notifications', notifications);
    localStorage.setItem('dateTimeFormat', dateTimeFormat);
  }, [darkMode, themeColor, fontSize, language, notifications, dateTimeFormat]);

  return (
    <div className="Setting">
      <Navbar/>
<div className="settingsContainer">
      <h2>Settings</h2>
      <div className="settingItem">
        <span>Dark Mode</span>
        <label className="switch">
          <input type="checkbox" checked={darkMode} onChange={toggleDarkMode} />
          <span className="slider round"></span>
        </label>
      </div>
      <div className="settingItem">
        <span>Theme Color</span>
        <input type="color" value={themeColor} onChange={(e) => setThemeColor(e.target.value)} />
      </div>
      <div className="settingItem">
        <span>Font Size</span>
        <select value={fontSize} onChange={(e) => setFontSize(e.target.value)}>
          <option value="small">Small</option>
          <option value="medium">Medium</option>
          <option value="large">Large</option>
        </select>
      </div>
      <div className="settingItem">
        <span>Language</span>
        <select value={language} onChange={(e) => setLanguage(e.target.value)}>
          <option value="en">English</option>
          <option value="fr">French</option>
          <option value="es">Spanish</option>
        </select>
      </div>
      <div className="settingItem">
        <span>Notifications</span>
        <select value={notifications} onChange={(e) => setNotifications(e.target.value)}>
          <option value="email">Email</option>
          <option value="push">Push</option>
          <option value="sms">SMS</option>
        </select>
      </div>
      <div className="settingItem">
        <span>Date/Time Format</span>
        <select value={dateTimeFormat} onChange={(e) => setDateTimeFormat(e.target.value)}>
          <option value="12h">12-Hour</option>
          <option value="24h">24-Hour</option>
        </select>
      </div>
    </div>
    </div>
    
  );
};

export default Settings;
