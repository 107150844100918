// Import necessary libraries
import React, { useState, useEffect } from 'react';
import firebase from '../../firebaseConfige'; // Ensure the correct path to your firebaseConfig.js file
import './account.scss'; // Import your SCSS file for styling
import Navbar from '../../component/topbar/Topbar';

const AccountPage = () => {
  const [user, setUser] = useState(null);
  const [formData, setFormData] = useState({
    displayName: '',
    email: '',
    password: ''
  });
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchUserInfo();
  }, []);

  const fetchUserInfo = async () => {
    try {
      const currentUser = firebase.auth().currentUser;
      if (currentUser) {
        setUser(currentUser);
        const { displayName, email } = currentUser;
        setFormData({ displayName, email, password: '' });
      } else {
        console.log('No user signed in.');
      }
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const { displayName, email, password } = formData;

    try {
      const currentUser = firebase.auth().currentUser;
      if (currentUser) {
        await currentUser.updateProfile({ displayName });
        await currentUser.updateEmail(email);
        if (password) {
          await currentUser.updatePassword(password);
        }
        // Update successful
        alert('Account information updated successfully!');
      }
    } catch (error) {
      setError('Error updating account information.');
      console.error('Error updating account:', error.message);
    }
  };

  return (
    <div className="accountPage">
      <h2>Edit Account</h2>
      <form onSubmit={handleUpdate}>
        <label htmlFor="displayName">Display Name:</label>
        <input
          type="text"
          id="displayName"
          name="displayName"
          value={formData.displayName}
          onChange={handleChange}
          required
        />
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <label htmlFor="password">New Password:</label>
        <input
          type="password"
          id="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
        />
        <button type="submit">Update Account</button>
      </form>
      {error && <p className="error">{error}</p>}
    </div>
  );
};

export default AccountPage;
