import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Upload from './page/upload/Upload';
import Login from '../src/page/login/UploaderLogin'
import History from './page/history/History';
import Sidebar from '../src/component/sidebar/sidebar';
import Dashboard from './page/dashboard/Dashboard';
import Settings from './page/settings/Settings';
import AccountPage from './page/user account/UserA';
import UploadBlog from './page/blogUpload/Blog';

import './App.scss';

const App = () => {
  return (
    <div className="app">
       <BrowserRouter>
       <Sidebar />
       <div className="content">
      <Routes>
        <Route path="/" element={<Login />} />  
        <Route path="/Dashboard" element={<Dashboard />} />  
        <Route path="/Upload" element={<Upload />} />  
        <Route path="/History" element={<History />} />  
        <Route path="/Settings" element={<Settings />} />  
        <Route path="/UserA" element={<AccountPage />} />  
        <Route path="/BlogUploader" element={<UploadBlog />} />  
      {/* <Route path="/UserA" element={< UserA/>} />  
        <Route path="/Doctor" element={<Doctor />} />  
        <Route path="/DetailDoc" element={<DetailDoc />} /> 
        <Route path="/Catagory" element={<Catagory />} />  
        {/* Add other routes here */}
      </Routes>
      </div>
    </BrowserRouter>
     
    </div>
  )
}

export default App