// BlogUploader.jsx
import React, { useState, useEffect } from 'react';
import firebase from '../../firebaseConfige'; // Ensure the correct path to your firebase.js file
import './blog.scss';
import Navbar from '../../component/topbar/Topbar';

const BlogUploader = () => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [uploadStatus, setUploadStatus] = useState('idle');
  const [userProfile, setUserProfile] = useState(null);

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const fetchUserProfile = async () => {
    try {
      const currentUser = firebase.auth().currentUser;
      if (currentUser) {
        const userProfileRef = firebase.firestore().collection('admin-user').doc(currentUser.uid);
        const userProfileSnapshot = await userProfileRef.get();
  
        if (userProfileSnapshot.exists) {
          setUserProfile(userProfileSnapshot.data());
        } else {
          console.log('No user profile found.');
        }
      } else {
        console.log('No user signed in.');
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  };

  const handleUpload = async () => {
    if (!title || !content) {
      alert('Please enter title and content to upload.');
      return;
    }
    setUploadStatus('uploading');
    try {
      const currentUser = firebase.auth().currentUser;
      if (!currentUser) {
        console.log('No user signed in.');
        return;
      }
      const userId = currentUser.uid;
      const blogData = {
        title: title,
        content: content,
        // You can add more fields here as per your requirements
      };
      await firebase.firestore().collection('blogs').doc(userId).collection('user-blogs').add(blogData);
      setUploadStatus('success');
      console.log('Blog data saved successfully.');
    } catch (error) {
      console.error('Error saving blog data:', error);
      setUploadStatus('error');
    }
  };

  return (
    <div className="blog-uploader">
      <Navbar />
      <div className="user-profile">
        {userProfile && (
          <img src={userProfile.profilePic} alt="Profile" />
        )}
      </div>
      <div className="blog-form">
        <h1>Write a Blog</h1>
        <input 
          type="text" 
          placeholder="Title" 
          value={title} 
          onChange={(e) => setTitle(e.target.value)} 
        />
        <textarea 
          placeholder="Content" 
          value={content} 
          onChange={(e) => setContent(e.target.value)} 
        />
        <button onClick={handleUpload} disabled={uploadStatus === 'uploading'}>
          {uploadStatus === 'idle' && 'Publish Blog'}
          {uploadStatus === 'uploading' && 'Publishing...'}
          {uploadStatus === 'success' && 'Published Successfully'}
          {uploadStatus === 'error' && 'Failed to Publish'}
        </button>
      </div>
    </div>
  );
};

export default BlogUploader;
