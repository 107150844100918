import React, { useState, useEffect } from 'react';
import './history.scss';
import Navbar from '../../component/topbar/Topbar';
import firebase from '../../firebaseConfige'; // Import your Firebase configuration
import Chart from 'chart.js/auto'; // Import Chart.js

const History = () => {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    fetchUserVideos();
  }, []);

  const fetchUserVideos = async () => {
    try {
      const currentUser = firebase.auth().currentUser;
      if (currentUser) {
        const userId = currentUser.uid;
        const userVideosRef = firebase.firestore().collection('videos').doc(userId).collection('user-videos');
        const snapshot = await userVideosRef.get();
        const userVideos = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setVideos(userVideos);

        // Update the Pie Chart
        updatePieChart(userVideos.length);
      } else {
        console.log('No user signed in.');
      }
    } catch (error) {
      console.error('Error fetching user videos:', error);
    }
  };

  const updatePieChart = (numVideos) => {
    const ctx = document.getElementById('videoAnalyticsChart').getContext('2d');

    new Chart(ctx, {
      type: 'pie',
      data: {
        labels: ['Uploaded Videos', 'Remaining Quota'],
        datasets: [
          {
            label: 'Videos Analytics',
            data: [numVideos, 10 - numVideos], // Assuming quota is 10, adjust as needed
            backgroundColor: ['#4CAF50', '#f44336'],
          },
        ],
      },
    });
  };

  const handleEdit = async (id, newData) => {
    try {
      const currentUser = firebase.auth().currentUser;
      if (currentUser) {
        const userId = currentUser.uid;
        const userVideosRef = firebase.firestore().collection('videos').doc(userId).collection('user-videos').doc(id);
        await userVideosRef.update(newData);
        // Fetch updated videos again if needed
        fetchUserVideos();
      } else {
        console.log('No user signed in.');
      }
    } catch (error) {
      console.error('Error editing video:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const currentUser = firebase.auth().currentUser;
      if (currentUser) {
        const userId = currentUser.uid;
        const userVideosRef = firebase.firestore().collection('videos').doc(userId).collection('user-videos').doc(id);
        await userVideosRef.delete();
        // Fetch updated videos again if needed
        fetchUserVideos();
      } else {
        console.log('No user signed in.');
      }
    } catch (error) {
      console.error('Error deleting video:', error);
    }
  };

  const renderVideos = () => {
    return videos.map((video) => (
      <div key={video.id} className="videoItem">
        <div className="videoPlayer">
          <video controls>
            <source src={video.videoURL} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="videoDetails">
          <h3>{video.title}</h3>
          <p>{video.description}</p>
          <div className="actionButtons">
            <button className="editButton" onClick={() => handleEdit(video.id, { title: 'New Title', description: 'New Description' })}>Edit</button>
            <button className="deleteButton" onClick={() => handleDelete(video.id)}>Delete</button>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div className="topbar">
      <Navbar />
      <div className="historyPage">

        {/* Analytics Container */}
        <div className="analyticsContainer">
          <h2>Video Analytics</h2>
          <canvas id="videoAnalyticsChart"></canvas>
        </div>
        <div className="historyContent">
          <h2>Late Video Upload</h2>
          <div className="videoList">
            {renderVideos()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default History;
