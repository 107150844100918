import React, { useState } from 'react';
import firebase from '../../firebaseConfige'; // Ensure correct import path
import { useNavigate } from 'react-router-dom';
import './login.scss';

const Login = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    const { email, password } = formData;

    try {
      // Sign in the user using email and password
      await firebase.auth().signInWithEmailAndPassword(email, password);
      // Redirect to the Home page upon successful login
      navigate('/Dashboard');
    } catch (error) {
      // Handle login errors
      setError('Invalid email or password. Please try again.');
      console.error('Error logging in:', error.message); // Log Firebase error message
    }
  };

  return (
    <div className="login">
      <div className="top">
        <div className="wrapper">
          <img
            className="logo"
            src="../image/photo_2024-03-11_11-38-35-removebg-preview.png"
            alt=""
          />
        </div>
      </div>
      <div className="container">
        <form onSubmit={handleLogin}>
          <h4>Sign In</h4>
          <input
            type="email"
            placeholder="Email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <input
            type="password"
            placeholder="Password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
          <button type="submit" className="loginButton">Sign In</button>
          {error && <div className="error">{error}</div>}
          <span>New to Mind pulse? <b>Sign up now.</b></span>
          <small>This page is protected by Google reCAPTCHA to ensure you're not a bot. <b>Learn more</b>.</small>
        </form>
      </div>
    </div>
  );
};

export default Login;
